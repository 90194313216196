
export default {
    name: 'OtpGroup',
    methods: {
        onFocus (index) {
            const prevChild = this.$refs.otpGroup.children[index - 1]
            if (prevChild && !prevChild.value) {
                prevChild.focus()
            }
        },

        focusNext (index) {
            if (this.$refs.otpGroup?.children[index + 1]) {
                this.$refs.otpGroup.children[index + 1]?.focus()
            }
        },

        focusPrev (index) {
            if (index > 0) {
                this.$refs.otpGroup.children[index - 1]?.focus()
            }
        }
    }
}


export default {
    name: 'OtpGroupInput',
    props: {
        value: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            digit: ''
        }
    },

    watch: {
        value () {
            this.setInitialValue()
        },
        digit (newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === '') {
                    this.emitPrev()
                } else {
                    this.emitNext()
                }

                this.$emit('input', newValue)
            }
        }
    },

    methods: {
        emitPrev () {
            this.$emit('prev')
        },
        emitNext () {
            this.$emit('next')
        },
        setInitialValue () {
            switch (typeof this.value) {
                case 'string':
                    this.digit = this.value
                    break
                case 'number':
                    this.digit = this.value?.toString()
                    break
                default:
                    this.digit = this.value?.data ? this.value?.data : ''
                    break
            }
        }
    }
}

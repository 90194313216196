
import StepTitle from '@/components/Register/StepTitle'
import Button from '@/components/UntitledUI/Button.vue'
import OneTimePassword from '@/components/common/OTP/OneTimePassword.vue'
import CountDown from '@/components/Onboarding/CountDown.vue'
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    components: { CountDown, OneTimePassword, Button, StepTitle, Icon },
    props: {
        verifyUrl: {
            type: String,
            required: true
        },
        resendUrl: {
            type: String,
            required: true
        },
        resendMethod: {
            type: String,
            default: 'get'
        },
        tfa: {
            type: String,
            default: undefined
        },
        steps: {
            type: Boolean,
            default: true
        },
        waitTime: {
            type: [Number, String],
            default: 0
        },
        phone: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            alertData: null,
            via: 'sms',
            countedDown: false,
            wait: this.waitTime,
            message: null,
            alertTimeout: null,
            verifyCodeForm: new this.$vForm({
                code: '',
                via: this.via,
                '2fa': undefined
            }),
            sendCodeForm: new this.$vForm({
                via: this.via,
                '2fa': undefined
            })
        }
    },
    methods: {
        async call () {
            this.verifyCodeForm.errors.clear()
            this.alertData = null
            this.verifyCodeForm.via = this.via
            this.verifyCodeForm['2fa'] = this.tfa ?? undefined
            await this.verifyCodeForm.post(this.verifyUrl)
                .then((res) => {
                    this.$emit('success', res.data)
                })
                .catch((e) => {
                    this.catchErrors(e, this.verifyCodeForm)
                    this.verifyCodeForm.code = ''
                })
        },
        async resendCode (via) {
            this.via = via
            this.sendCodeForm.errors.clear()
            this.alertData = null
            this.sendCodeForm.via = this.via
            this.sendCodeForm['2fa'] = this.tfa ?? undefined
            await this.sendCodeForm[this.resendMethod](this.resendUrl)
                .then((res) => {
                    const msg = via === 'sms'
                        ? this.$t('لقد تم إعادة إرسال رقم التحقق عن طريق الرسائل النصية بنجاح.')
                        : this.$t('لقد تم إعادة إرسال رقم التحقق عن طريق الواتساب بنجاح.')
                    this.wait = res?.data?.wait ?? this.waitTime
                    this.countedDown = false
                    this.alert({ type: 'success', body: msg, time: 3000 })
                })
                .catch(e => this.catchErrors(e, this.sendCodeForm))
        },
        catchErrors (e, form) {
            if (e.response?.status && e.response?.status > 499 && e.response?.status < 400) {
                this.alert({ type: 'danger', body: this.$t('لقد حصل خطأ ما'), time: 3000 })
                throw e
            }
            if (form.errors.any()) {
                this.alert({ type: 'danger', body: form.errors.flatten()[0], time: 3000 })
            }
        },
        alert ({ type, head, body, time }) {
            this.alertData = { type, head, body, time }
            if (typeof time === 'number') {
                clearTimeout(this.alertTimeout)
                this.alertTimeout = setTimeout(() => { this.alertData = null }, time)
            }
        }
    }
}


export default {
    name: 'CountDown',
    props: {
        seconds: {
            type: [Number, String],
            required: true
        }
    },
    data () {
        return {
            s: 0,
            m: 0,
            started: false,
            interval: null
        }
    },
    computed: {
        text () {
            return ('0' + this.m).slice(-2) + ':' + ('0' + this.s).slice(-2)
        }
    },
    watch: {
        s () {
            if (this.started && this.s < 0 && this.m !== 0) {
                this.m--
                return
            }
            if (this.started && this.s <= 0 && this.m <= 0) {
                clearInterval(this.interval)
                this.interval = null
                this.$emit('finished')
            }
        },
        m () {
            if (this.started) {
                this.s = 59
            }
        },
        seconds () {
            this.init()
        }
    },
    created () {
        this.init()
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        init () {
            let seconds = this.seconds
            if (typeof seconds !== 'number') {
                try {
                    seconds = parseInt(seconds)
                } catch (e) {
                    seconds = 60
                }
            }
            this.s = this.seconds % 60
            this.m = Math.min(Math.floor(this.seconds / 60), 59)

            clearInterval(this.interval)

            this.interval = setInterval(() => {
                this.s--
            }, 1000)

            this.$nextTick(() => {
                this.started = true
            })
        }
    },
    emits: ['finished']
}


export default {
    name: 'OtpComponent', // Giving our component a name
    props: {
        value: {
            type: String,
            default: ''
        }, // This is where we receive input from the user
        count: { // This is the place where users will specify the number of input fields.
            type: Number,
            required: true
        }
    },

    data () {
        return {
            error: false, // We start without errors
            errorMessage: '', // No error message initially
            digits: Array(this.count).fill('') // Create an array to store our OTP digits
        }
    },

    computed: {
        isValid () {
            return this.digits.length === this.count // We check if all OTP digits are filled
        }
    },

    watch: {
        value: {
            handler () {
                this.digits = this.value && typeof this.value === 'string'
                    ? this.value?.split('')
                        .concat(Array(this.count - this.value.length).fill(''))
                        .slice(0, this.count)
                    : Array(this.count).fill('')
            },
            immediate: true
        }
    },

    methods: {
        onInput (index, digit) {
            // This function handles user input for each OTP digit
            switch (typeof digit) {
                case 'string':
                    this.digits[index] = digit
                    break
                case 'number':
                    this.digits[index] = digit?.toString()
                    break
                default:
                    this.digits[index] = digit?.data ? digit?.data : ''
                    break
            }

            this.$emit('input', this.digits?.join(''))
            // We update the OTP digits and notify the parent component of changes
        },

        onKeyDown (e) {
            if (e && typeof e.key === 'string' && !['Backspace', 'Tab', 'Enter'].includes(e.key) && !e.key.match(/\d/) && !(e.metaKey || e.key === 'v')) {
                e.preventDefault()
            }
        },

        onPaste (index, event) {
            event.preventDefault()
            const clipboardData = event?.clipboardData || window?.clipboardData

            if (clipboardData) {
                const pastedText = clipboardData.getData('text').trim()

                // Remove non-digit characters from the pasted text
                const parsedPastedText = pastedText.replace(/\D/g, '')

                // Initialize an array for the new digits
                const newDigits = Array(this.count).fill('')

                // Check if the pasted text length exceeds the available input fields
                if (this.digits.length <= parsedPastedText?.length) {
                    this.errro = true
                    this.errorMessage = 'Invalid characters in the pasted OTP.'
                }

                // Copy parsed digits into the newDigits array, starting from the current index
                for (let i = 0; i < parsedPastedText.length; i++) {
                    if (index + i < newDigits.length) {
                        newDigits[index + i] = parsedPastedText[i]
                    }
                }

                // Update the digits with the newly pasted values
                this.digits = newDigits
            } else {
                // Handle cases where clipboard data is unavailable
                this.error = true
                this.errorMessage = 'Invalid characters in the pasted OTP.'
            }
        },

        onBlur () {
            // This function is called when the input field loses focus
            this.checkDigits()
        },

        clearDigits () {
            // This function clears all OTP digits and errors
            this.error = false
            this.digits.fill('')
            this.errorMessage = ''
        },

        checkDigits () {
            // You can add custom validation logic here
            if (!this.isValid) {
                this.errorMessage = 'Please enter a valid OTP'
                this.error = true
            } else {
                this.errorMessage = ''
                this.error = false
            }
        }
    }
}

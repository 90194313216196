import { render, staticRenderFns } from "./OneTimePassword.vue?vue&type=template&id=19e68d66&"
import script from "./OneTimePassword.vue?vue&type=script&lang=js&"
export * from "./OneTimePassword.vue?vue&type=script&lang=js&"
import style0 from "./OneTimePassword.vue?vue&type=style&index=0&id=19e68d66&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OtpGroupInput: require('/var/www/qanoniah.com/components/common/OTP/OtpGroupInput.vue').default,OtpGroup: require('/var/www/qanoniah.com/components/common/OTP/OtpGroup.vue').default,Otp: require('/var/www/qanoniah.com/components/common/OTP/Otp.vue').default})
